/* eslint-disable max-len */
import { ReactElement } from 'react';
import CourseCodeCertificationAndFundingPanel from 'src/components/FormLayouts/CouseCode/CourseCodeCertificationAndFundingPanel';
import CourseCodeCteOnlyPanel from 'src/components/FormLayouts/CouseCode/CourseCodeCteOnlyPanel';
import CouseCodeInformationPanel from 'src/components/FormLayouts/CouseCode/CourseCodeInformationPanel';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearCourseCode,
  saveCourseCode,
  selectCourseCodeCourseCode,
  selectCourseCodeDataEntryMode,
  selectCourseCodeFormViewModel,
  selectCourseCodeLoadingCourseCode,
  selectCourseCodeLoadingFormViewModel,
  setCourseCodeDataEntryMode,
} from '../../../../redux/slices/courseCodeSlice';
import {
  fetchCourseCodeById,
  fetchCourseCodeFormViewModel,
} from '../../../../redux/thunks/courseCodeThunks';
import {
  createCourseCode,
  updateCourseCode,
} from '../../../../services/courseCode.service';
import { appRoutePaths } from '../../../../services/route.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { CourseCodeFormViewModel } from '../../../../types/CourseCodeFormViewModel';
import { CourseCodeModel } from '../../../../types/CourseCodeModel';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_MDE_OTSS } from '../../../../types/NavigationTabs';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { CourseCodeValidationSchema } from '../../../../types/Validation/CourseCodeValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import './CourseCodeInput.css';

const CourseCodeInput = (): ReactElement => {
  const courseCodeModel: CourseCodeModel = useAppSelector(
    selectCourseCodeCourseCode
  );
  const formViewModel: CourseCodeFormViewModel = useAppSelector(
    selectCourseCodeFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectCourseCodeLoadingFormViewModel
  );
  const isLoadingCourseCodeModel = useAppSelector(
    selectCourseCodeLoadingCourseCode
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectCourseCodeDataEntryMode
  );
  const isLoading = isLoadingCourseCodeModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
    {
      statusCode: 409,
      howToFix:
        'The provided Course Code ID is already in the system. Use another value, or return to the list and edit the existing Course Code.',
    },
  ];

  return (
    <ManualInputPage<CourseCodeModel, CourseCodeFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'course-code-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_MDE_OTSS)}
      classes={{ pageClass: 'data-input--course-codes--input' }}
      parameterOptions={{
        routeParameterName: 'courseCodeId',
        identityName: courseCodeModel.courseCode,
      }}
      routes={{
        backBarRoute: appRoutePaths.CourseCodeList,
        cancelRoute: appRoutePaths.CourseCodeList,
        createSuccessRoute: appRoutePaths.CourseCodeInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchCourseCodeById,
        loadFormDetail: fetchCourseCodeFormViewModel,
        setEntryMode: setCourseCodeDataEntryMode,
        setData: saveCourseCode,
        clearData: clearCourseCode,
        onCreate: createCourseCode,
        onUpdate: updateCourseCode,
      }}
      formDetails={{
        validationSchema: CourseCodeValidationSchema,
        initialFormValues: courseCodeModel,
        isFormModelEmpty:
          !formViewModel.courseLevelCharacteristicOptions?.length,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'course code',
        pageHeader: 'MDE/OTSS',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="course-code-input-child">
        <CouseCodeInformationPanel />
        <CourseCodeCteOnlyPanel />
        <CourseCodeCertificationAndFundingPanel />
      </div>
    </ManualInputPage>
  );
};

export default CourseCodeInput;
