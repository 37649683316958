import { FileErrorCode, FileStatus, IFileModel } from '../generated';
import { FileProcessingDataModel } from './FileProcessingDataModel';
import { SelectOption } from './SelectOption';

export interface FileModel extends IFileModel {
  id: string;
  uploadedBy: string;
  fileName: string;
  uploadDateTimeUtc: string;
  status: FileStatus;
  errorCode: FileErrorCode;
  canDownload: boolean;
  errorCount: number;
  districtId: string;
  processingData: FileProcessingDataModel;
}

export const getFileStatusList = (): SelectOption[] => {
  const list: SelectOption[] = [];

  list.push({ text: FileStatus.FAILED, value: FileStatus.FAILED });
  list.push({ text: FileStatus.PENDING, value: FileStatus.PENDING });
  list.push({ text: FileStatus.SUCCESS, value: FileStatus.SUCCESS });
  return list;
};
