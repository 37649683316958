import { useFormikContext } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectLEAManagementDataEntryMode,
  selectLEAManagementFormViewModel,
} from '../../../redux/slices/leaManagementSlice';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { LEAManagementFormViewModel } from '../../../types/LEAManagementFormViewModel';
import { LEAManagementModel } from '../../../types/LEAManagementModel';
import { PanelType } from '../../../types/PanelType';
import { FormDropdownChangeEventConfig } from '../../../types/propTypes/FormDropdownPropTypes';
import { FormTextboxChangeEventConfig } from '../../../types/propTypes/FormTextboxPropTypes';
import { LEAManagementValidationEditSchema } from '../../../types/Validation/LEAManagementValidationEditSchema';
import { LEAManagementValidationSchema } from '../../../types/Validation/LEAManagementValidationSchema';
import {
  getCountyAnsiCodeFromOptions,
  isStateAdmin,
} from '../../../utilities/userUtilities';
import FormDropdown from '../../FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';

const LEAManagementMailingAddressPanel = (): ReactElement => {
  const { values, setFieldValue } = useFormikContext<LEAManagementModel>();
  const formViewModel: LEAManagementFormViewModel = useAppSelector(
    selectLEAManagementFormViewModel
  );

  const [isShowMailingCounty, setIsShowMailingCounty] = useState(false);
  const DEFAULT_STATE = 'Mississippi';

  const [areMailingAddressFieldsRequired, setAreMailingAddressFieldsRequired] =
    useState(false);

  const getRequiredMailingAddressLabel = (): string => {
    return areMailingAddressFieldsRequired ? 'forced-required-label' : '';
  };

  const dataEntryMode: DataEntryMode = useAppSelector(
    selectLEAManagementDataEntryMode
  );

  const updateMailingAddressRequiredFlag = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    await setFieldValue(config.field, config.value);
    const updatedValues = {
      ...values,
      [config.field]: config.value,
    };
    const isRequired =
      config.value === '' &&
      updatedValues.mailingAddressStreetNumberAndName === '' &&
      updatedValues.mailingCity === '' &&
      updatedValues.mailingState === '' &&
      updatedValues.mailingZipCode === ''
        ? false
        : true;
    setAreMailingAddressFieldsRequired(isRequired);
  };

  const updateShowMailingCounty = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const showCounty = config.value === DEFAULT_STATE ? true : false;
    setIsShowMailingCounty(showCounty);
    if (!showCounty) {
      setFieldValue('mailingCounty', '');
      setFieldValue('mailingCountyAnsiCode', '');
    }

    if (config.value === undefined) {
      setAreMailingAddressFieldsRequired(false);
    } else {
      setAreMailingAddressFieldsRequired(true);
    }
  };

  const updateMailingCountyAnsiCode = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const mailingCountyAnsiCode = getCountyAnsiCodeFromOptions(
      formViewModel.countyAnsiCodeOptions,
      config
    );
    /* istanbul ignore next */
    if (config.setValueHook && mailingCountyAnsiCode) {
      config.setValueHook('mailingCountyAnsiCode', mailingCountyAnsiCode);
    }
  };

  useEffect(() => {
    const isRequired =
      values.mailingAddressStreetNumberAndName === '' &&
      values.mailingCity === '' &&
      (values.mailingState === '' || values.mailingState === undefined) &&
      values.mailingZipCode === ''
        ? false
        : true;
        
    setAreMailingAddressFieldsRequired(isRequired);
    const showCounty = values.mailingState === DEFAULT_STATE ? true : false;
    setIsShowMailingCounty(showCounty);

    if (!showCounty) {
      setFieldValue('mailingCounty', '');
      setFieldValue('mailingCountyAnsiCode', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Panel panelType={PanelType.INFO} heading="Mailing Address">
        <div className="field-row">
          <FormTextbox
            displayName="Address Street Number and Name"
            field="mailingAddressStreetNumberAndName"
            classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
            validationSchema={
              dataEntryMode === 'new'
                ? LEAManagementValidationSchema
                : LEAManagementValidationEditSchema
            }
            onChangeEvent={updateMailingAddressRequiredFlag}
            disabled={!isStateAdmin()}
          />
          <FormTextbox
            displayName="Apt., Suite, Unit, Building, Floor, Etc."
            field="mailingAptSuiteBuildingFloorNumber"
            validationSchema={
              dataEntryMode === 'new'
                ? LEAManagementValidationSchema
                : LEAManagementValidationEditSchema
            }
            disabled={!isStateAdmin()}
          />
        </div>
        <div className="field-row">
          <FormTextbox
            displayName="City"
            field="mailingCity"
            classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
            validationSchema={
              dataEntryMode === 'new'
                ? LEAManagementValidationSchema
                : LEAManagementValidationEditSchema
            }
            onChangeEvent={updateMailingAddressRequiredFlag}
            disabled={!isStateAdmin()}
          />
          <FormDropdown
            displayName="State"
            field="mailingState"
            options={formViewModel.stateOptions}
            classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
            validationSchema={
              dataEntryMode === 'new'
                ? LEAManagementValidationSchema
                : LEAManagementValidationEditSchema
            }
            onChangeEvent={updateShowMailingCounty}
            disabled={!isStateAdmin()}
            isClearable
          />
          <FormTextbox
            displayName="Zip Code"
            field="mailingZipCode"
            classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
            validationSchema={
              dataEntryMode === 'new'
                ? LEAManagementValidationSchema
                : LEAManagementValidationEditSchema
            }
            onChangeEvent={updateMailingAddressRequiredFlag}
            disabled={!isStateAdmin()}
          />
        </div>
        {isShowMailingCounty && (
          <div className="field-row">
            <FormDropdown
              displayName="County"
              field="mailingCounty"
              options={formViewModel.countyOptions}
              classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
              validationSchema={
                dataEntryMode === 'new'
                  ? LEAManagementValidationSchema
                  : LEAManagementValidationEditSchema
              }
              onChangeEvent={updateMailingCountyAnsiCode}
              disabled={!isStateAdmin()}
              isClearable
            />
            <FormTextbox
              displayName="County ANSI Code"
              field="mailingCountyAnsiCode"
              disabled={true}
            />
          </div>
        )}
      </Panel>
    </>
  );
};

export default LEAManagementMailingAddressPanel;
