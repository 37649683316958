/* eslint-disable max-len */
import { matchPath } from 'react-router';
import { appRoutePaths } from '../services/route.service';
import { canUserAccessRoute } from '../services/security.service';
import { ExtendedLink } from '../types/ExtendedLink';
import { FeatureAccessPermissions } from '../types/FeatureAccessPermissions';
import { NavigationTabItem } from '../types/NavigationTabs';
import { formatRoutePath } from './helperUtilities';

export const getRoutingAccessObject = (
  parentId?: string,
  childId?: string
): Record<string, FeatureAccessPermissions[]> => ({
  [appRoutePaths.ReportDashboard]: [FeatureAccessPermissions.REPORT_ACCESS],
  [appRoutePaths.ReportDisplay(parentId)]: [
    FeatureAccessPermissions.REPORT_ACCESS,
  ],
  [appRoutePaths.FileUpload]: [FeatureAccessPermissions.FILE_UPLOAD_ACCESS],
  [appRoutePaths.SubmissionStatus]: [
    FeatureAccessPermissions.FILE_STATUS_ACCESS,
  ],
  [appRoutePaths.FileDetail(parentId)]: [
    FeatureAccessPermissions.FILE_STATUS_ACCESS,
  ],
  [appRoutePaths.DataQualityDashboard]: [
    FeatureAccessPermissions.DATA_QUALITY_ACCESS,
  ],
  [appRoutePaths.DataQualityCategory(parentId)]: [
    FeatureAccessPermissions.DATA_QUALITY_ACCESS,
  ],
  [appRoutePaths.DataQualityDetail(parentId, childId)]: [
    FeatureAccessPermissions.DATA_QUALITY_ACCESS,
  ],
  [appRoutePaths.Accreditation]: [
    FeatureAccessPermissions.ACCREDITATION_ACCESS,
  ],
  [appRoutePaths.MSISRequest]: [FeatureAccessPermissions.MSIS_ID_ACCESS],
  [appRoutePaths.MSISRequestNew]: [FeatureAccessPermissions.MSIS_ID_ACCESS],
  [appRoutePaths.MSISRequestDetail(parentId)]: [
    FeatureAccessPermissions.MSIS_ID_ACCESS,
  ],
  [appRoutePaths.StudentOwnership]: [
    FeatureAccessPermissions.STUDENT_OWNERSHIP_ACCESS,
  ],
  [appRoutePaths.StudentOwnershipDetail(parentId)]: [
    FeatureAccessPermissions.STUDENT_OWNERSHIP_ACCESS,
  ],
  [appRoutePaths.CharterSchoolAuthorizingOrganizationList]: [
    FeatureAccessPermissions.CHARTER_SCHOOL_ACCESS,
  ],
  [appRoutePaths.NewCharterSchoolAuthorizingOrganization]: [
    FeatureAccessPermissions.CHARTER_SCHOOL_ACCESS,
  ],
  [appRoutePaths.NewCharterSchoolManagementOrganization]: [
    FeatureAccessPermissions.CHARTER_SCHOOL_ACCESS,
  ],
  [appRoutePaths.NewCharterSchoolAssignment]: [
    FeatureAccessPermissions.CHARTER_SCHOOL_ACCESS,
  ],
  [appRoutePaths.LEAManagementList]: [
    FeatureAccessPermissions.LEA_MANAGEMENT_ACCESS,
  ],
  [appRoutePaths.LEAManagementInput(parentId)]: [
    FeatureAccessPermissions.LEA_MANAGEMENT_ACCESS,
  ],
  [appRoutePaths.SchoolManagementList]: [
    FeatureAccessPermissions.SCHOOL_MANAGEMENT_ACCESS,
  ],
  [appRoutePaths.SchoolManagementInput(parentId)]: [
    FeatureAccessPermissions.SCHOOL_MANAGEMENT_ACCESS,
  ],
  [appRoutePaths.LEASharingList]: [
    FeatureAccessPermissions.DISTRICT_SHARING_ACCESS,
  ],
  [appRoutePaths.LEASharingInput(parentId)]: [
    FeatureAccessPermissions.DISTRICT_SHARING_ACCESS,
  ],
  [appRoutePaths.SalaryScaleList]: [
    FeatureAccessPermissions.SALARY_SCALE_ACCESS,
  ],
  [appRoutePaths.SeaOrganizationList]: [
    FeatureAccessPermissions.SEA_ORGANIZATION_ACCESS,
  ],
  [appRoutePaths.SeaOrganizationInput(parentId)]: [
    FeatureAccessPermissions.SEA_ORGANIZATION_ACCESS,
  ],
  [appRoutePaths.CourseCodeList]: [FeatureAccessPermissions.COURSE_CODE_ACCESS],
  [appRoutePaths.CourseCodeInput(parentId)]: [
    FeatureAccessPermissions.COURSE_CODE_ACCESS,
  ],
  [appRoutePaths.NonPublicStudentEntryList]: [
    FeatureAccessPermissions.NON_PUBLIC_STUDENT_ACCESS,
  ],
  [appRoutePaths.NonPublicStudentEntryInput(parentId)]: [
    FeatureAccessPermissions.NON_PUBLIC_STUDENT_ACCESS,
  ],
  [appRoutePaths.NonPublicStudentEntryScheduleInput(parentId, childId)]: [
    FeatureAccessPermissions.NON_PUBLIC_STUDENT_ACCESS,
  ],
  [appRoutePaths.SummerActivityList]: [
    FeatureAccessPermissions.SUMMER_ACTIVITY_ACCESS,
  ],
  [appRoutePaths.SummerActivityInput(parentId)]: [
    FeatureAccessPermissions.SUMMER_ACTIVITY_ACCESS,
  ],
  [appRoutePaths.StudentInterventionList]: [
    FeatureAccessPermissions.SUMMER_ACTIVITY_ACCESS,
  ],
  [appRoutePaths.StudentInterventionInput(parentId)]: [
    FeatureAccessPermissions.SUMMER_ACTIVITY_ACCESS,
  ],
  [appRoutePaths.UserList]: [FeatureAccessPermissions.USER_MANAGEMENT_ACCESS],
  [appRoutePaths.UserDetail(parentId)]: [
    FeatureAccessPermissions.USER_MANAGEMENT_ACCESS,
  ],
  [appRoutePaths.UserEdit(parentId)]: [
    FeatureAccessPermissions.USER_MANAGEMENT_ACCESS,
  ],
  [appRoutePaths.Feedback]: [FeatureAccessPermissions.APPLICATION_ACCESS],
});

const isRouteExactMatch = (
  path: string,
  specifiedRoutes: string[]
): boolean => {
  const sanitizedRoute = formatRoutePath(path);
  let isMatch = false;
  specifiedRoutes.forEach((page) => {
    if (!isMatch) {
      const item = matchPath(
        {
          path: page,
        },
        sanitizedRoute
      );

      if (item !== null) {
        isMatch = true;
      }
    }
  });

  return isMatch;
};

export const isRouteExactMatchOfSublinks = (
  path: string,
  subLinks: ExtendedLink[]
): boolean => {
  const subLinksWithAccess = subLinks
    .filter((x) => x.to !== undefined)
    .map((x) => x.to);

  return isRouteExactMatch(path, subLinksWithAccess);
};

export const isRouteAChildOfSublinks = (
  path: string,
  subLinks: ExtendedLink[]
): boolean => {
  const cleanPath = (locationPath: string): string => {
    return formatRoutePath(locationPath).toLowerCase().trim();
  };

  let isMatch = false;
  const routesWithAccess = subLinks
    .filter((x) => x.to !== undefined)
    .map((x) => {
      if (x.baseRoute) {
        return x.baseRoute;
      } else {
        return x.to;
      }
    });

  const currentPath = cleanPath(path);
  routesWithAccess.forEach((page) => {
    const linkRoute = cleanPath(page);
    if (!isMatch) {
      isMatch = currentPath.startsWith(linkRoute);
    }
  });

  return isMatch;
};

export const isValidAfterLoginRoute = (path: string): boolean => {
  if (!path) {
    return false;
  }
  const notValidRoutes = [
    appRoutePaths.Login,
    appRoutePaths.Dashboard,
    appRoutePaths.Logout,
    appRoutePaths.GetToken,
  ];

  return !isRouteExactMatch(path, notValidRoutes);
};

export const isReportPage = (path: string): boolean => {
  if (!path) {
    return false;
  }

  const reportRoutes = [
    appRoutePaths.ReportDashboard,
    appRoutePaths.ReportDisplay(),
  ];

  return isRouteExactMatch(path, reportRoutes);
};

export const isDistrictRefreshRoute = (path: string): boolean => {
  if (!path) {
    return false;
  }

  const NavigationRefreshRoutes: string[] = [
    appRoutePaths.Accreditation,
    appRoutePaths.Dashboard,
    appRoutePaths.DataQualityDashboard,
    appRoutePaths.FileUpload,
    appRoutePaths.MSISRequest,
    appRoutePaths.NonPublicStudentEntryList,
    appRoutePaths.ReportDashboard,
    appRoutePaths.SalaryScaleList,
    appRoutePaths.SchoolManagementList,
    appRoutePaths.StudentOwnership,
    appRoutePaths.SubmissionStatus,
    appRoutePaths.SummerActivityList,
    appRoutePaths.UserList,
    appRoutePaths.StudentInterventionList
  ];

  const forceReloadRoutes: string[] = NavigationRefreshRoutes.map((nav) => nav);

  return isRouteExactMatch(path, forceReloadRoutes);
};

export const buildUserAccessNavigationTabItems = (
  tabs: NavigationTabItem[]
): NavigationTabItem[] => {
  const list: NavigationTabItem[] = [];
  tabs.forEach((element) => {
    if (canUserAccessRoute(element.routePath)) {
      list.push(element);
    }
  });
  return list;
};
