import { useFormikContext } from 'formik';
import { ReactElement, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { CourseCodeFormViewModel } from 'src/types/CourseCodeFormViewModel';
import { CourseCodeModel } from 'src/types/CourseCodeModel';
import FormCheckbox from '../../../components/FormInputs/FormCheckbox/FormCheckbox';
import FormCheckboxList from '../../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import FormDateSelector from '../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../components/FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../components/FormInputs/FormRadioList/FormRadioList';
import FormTextarea from '../../../components/FormInputs/FormTextarea/FormTextarea';
import FormTextbox from '../../../components/FormInputs/FormTextbox/FormTextbox';
import Panel from '../../../components/Panel/Panel';
import {
  selectCourseCodeDataEntryMode,
  selectCourseCodeFormViewModel,
} from '../../../redux/slices/courseCodeSlice';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { PanelType } from '../../../types/PanelType';
import { CourseCodeValidationSchema } from '../../../types/Validation/CourseCodeValidationSchema';
import { isStateAdmin } from '../../../utilities/userUtilities';
import { SelectOption } from 'src/types/SelectOption';
import { FormDropdownChangeEventConfig } from 'src/types/propTypes/FormDropdownPropTypes';

const CourseCodeInformationPanel = (): ReactElement => {
  const { setFieldValue } = useFormikContext<CourseCodeModel>();
  const [scedCourseLevel, setScedCouseLevel] = useState('');
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectCourseCodeDataEntryMode
  );
  const formViewModel: CourseCodeFormViewModel = useAppSelector(
    selectCourseCodeFormViewModel
  );
  const handleScedCourseLevel = (
    config: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (config.target.name === 'scedCourseLevel') {
      if (scedCourseLevel === config.target.value) {
        setFieldValue('scedCourseLevel', '');
      } else {
        setScedCouseLevel(config.target.value);
      }
    }
  };
const [maxGradeSpan, setMaxGradeSpan] = useState(
  formViewModel.scedGradeSpanOptions
);
const [maxGradeSpanDisabled, setMaxGradeSpanDisabled] = useState(true);

const getValuesAboveIndex = (
  array: SelectOption[],
  currentIndex: number
): SelectOption[] => {
  const result = [];
  for (let i = currentIndex; i < array.length; i++) {
    result.push(array[i]);
  }
  return result;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const updateMaxGradeSpan = (config: FormDropdownChangeEventConfig): any => {
  const currentIndex = formViewModel.scedGradeSpanOptions.findIndex(
    (element) => element.value === config.value
  );

  setMaxGradeSpanDisabled(false);
  setMaxGradeSpan(
    getValuesAboveIndex(formViewModel.scedGradeSpanOptions, currentIndex)
  );
};
  return (
    <Panel panelType={PanelType.INFO} heading="Course Code Information">
      <div className="field-row field-row-grid-1-3-2">
        <FormTextbox
          displayName="Course Code"
          field="courseCode"
          validationSchema={CourseCodeValidationSchema}
          disabled={
            !isStateAdmin()
              ? true
              : dataEntryMode === DataEntryMode.EDIT
              ? true
              : false
          }
        />
        <FormTextbox
          displayName="Course Title"
          field="courseTitle"
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormTextbox
          displayName="Course Department Name"
          field="courseDepartmentName"
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>

      <div className="field-row">
        <FormTextarea
          displayName="Course Description"
          field="courseDescription"
          rows={3}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>

      <div className="field-row">
        <FormCheckbox
          displayName="Course Aligned with MS CCR Standards?"
          field="courseAlignedWithStandards"
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>

      <div className="field-row field-row-grid-2-1-1">
        <FormDropdown
          displayName="Course Level Characteristics"
          field="courseLevelCharacteristic"
          options={formViewModel.courseLevelCharacteristicOptions}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
      <div className="field-row field-row-grid-1-1-2">
        <FormTextbox
          displayName="SCED Sequence of Course"
          field="scedSequenceOfCourse"
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormDropdown
          displayName="End of Course Assessment Requirement?"
          field="assessmentIdentifier"
          options={formViewModel.endOfCourseAssessmentRequirementOptions}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>

      <div className="field-row field-row-grid-1-1-1-1">
        <FormDropdown
          displayName="SCED Low Grade Span"
          field="scedGradeSpanMin"
          options={formViewModel.scedGradeSpanOptions}
          validationSchema={CourseCodeValidationSchema}
          onChangeEvent={updateMaxGradeSpan}
          disabled={!isStateAdmin()}
        />
        <FormDropdown
          displayName="SCED High Grade Span"
          field="scedGradeSpanMax"
          options={maxGradeSpan}
          validationSchema={CourseCodeValidationSchema}
          disabled={maxGradeSpanDisabled}
        />
        <FormDateSelector
          displayName="Course Begin Date"
          field="courseBeginDate"
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormDateSelector
          displayName="Course Inactive Date"
          field="courseEndDate"
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormCheckboxList
          displayName="Carnegie Units"
          field="availableCarnegieUnitCredit"
          options={formViewModel.availableCarnegieUnitCreditOptions}
          classConfig={{ containerClasses: 'carnegie-units-container' }}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>

      <div className="field-row">
        <FormCheckbox
          displayName="Core Subject?"
          field="coreAcademicCourse"
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
      <div className="field-row field-row-grid-2-1">
        <FormRadioList
          displayName="SCED Course Level"
          field="scedCourseLevel"
          options={formViewModel.scedCourseLevelOptions}
          classConfig={{ containerClasses: 'sced-course-level-container' }}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
          onClick={handleScedCourseLevel}
        />
      </div>
      <div className="field-row field-row-grid-1-1-1">
        <FormDropdown
          displayName="SCED Course Subject Area"
          field="scedCourseSubjectArea"
          options={formViewModel.scedCourseSubjectAreaOptions}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
    </Panel>
  );
};

export default CourseCodeInformationPanel;
