/* eslint-disable max-len */
import { useField, useFormikContext } from 'formik';
import { find } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { fetchStudentInterventionListItems } from 'src/redux/thunks/studentInterventionThunks';
import { abortPromiseOnUnmount } from 'src/services/base.service';
import { StudentInterventionFormViewModel } from 'src/types/StudentInterventionFormViewModel';
import { StudentInterventionListItemModel } from 'src/types/StudentInterventionListItemModel';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectSchoolYear,
  selectStudentInterventionDataEntryMode,
  selectStudentInterventionFormViewModel,
  selectStudentInterventionListItems,
  selectStudentInterventionStudentIntervention,
} from '../../../redux/slices/studentInterventionSlice';
import { isValidCode } from '../../../services/base.service';
import { getStudentByMSISId } from '../../../services/studentIntervention.service';
import { ButtonSize } from '../../../types/ButtonSize';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { PanelType } from '../../../types/PanelType';
import { StudentInterventionModel } from '../../../types/StudentInterventionModel';
import { StudentInterventionMsisidSearchResultModel } from '../../../types/StudentInterventionMsisidSearchResultModel';
import { StudentInterventionValidationSchema } from '../../../types/Validation/StudentInterventionValidationSchema';
import { isStateNonAdmin } from '../../../utilities/userUtilities';
import ActionButton from '../../ActionButton/ActionButton';
import FormDateSelector from '../../FormInputs/FormDateSelector/FormDateSelector';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';

const StudentIntervention_StudentInterventionPanel = (): ReactElement => {
  const { values, setFieldValue, setFieldTouched, dirty } =
    useFormikContext<StudentInterventionModel>();
  const dispatch = useAppDispatch();

  const [, msisIdMetaProps] = useField('msisId');
  const getIsMsisIDValid = (): boolean => !msisIdMetaProps?.error;

  const dataEntryMode: DataEntryMode = useAppSelector(
    selectStudentInterventionDataEntryMode
  );

  const listItem: StudentInterventionListItemModel[] = useAppSelector(
    selectStudentInterventionListItems
  );

  const formView: StudentInterventionFormViewModel = useAppSelector(
    selectStudentInterventionFormViewModel
  );

  const studentInterventionInput: StudentInterventionModel = useAppSelector(
    selectStudentInterventionStudentIntervention
  );
  const newModeSchoolYear = useAppSelector(selectSchoolYear);

  const hasMatch: boolean =
    dataEntryMode === DataEntryMode.EDIT || values.firstName.length > 0;

  const [searching, setSearching] = useState(false);
  const [hasSearchMatch, setHasSearchMatch] = useState(hasMatch);
  const [searchMessage, setSearchMessage] = useState('');

  useEffect(() => {
    let promise: unknown;
    let schoolYear = '';

    if (DataEntryMode.NEW) {
      schoolYear = newModeSchoolYear || formView.defaultSchoolYear;
    } else {
      schoolYear = studentInterventionInput.schoolYear;
    }

    if (schoolYear) {
      promise = dispatch(fetchStudentInterventionListItems(schoolYear));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      (dataEntryMode === DataEntryMode.NEW ||
        dataEntryMode === DataEntryMode.EDIT) &&
      values.msisId !== ''
    ) {
      const listItemRecord: StudentInterventionListItemModel | undefined = find(
        listItem,
        {
          msisId: values.msisId,
          // schoolYear: values.schoolYear, // uncomment this once second panel with lea information is developed
        }
      );

      if (
        dirty &&
        // studentInterventionInput.schoolYear !== values.schoolYear && // uncomment this line once second panel is developed.
        listItemRecord !== undefined
      ) {
        setSearchMessage(
          'A student with this MSIS ID already has an entry.  Please check the MSIS ID and try again.'
        );
      } else {
        setSearchMessage('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.msisId]);

  const searchDisabled =
    dataEntryMode === DataEntryMode.EDIT ||
    !getIsMsisIDValid() ||
    searching ||
    hasSearchMatch;

  const handleMSISSearch = async (): Promise<void> => {
    const setUserFields = (
      data: StudentInterventionMsisidSearchResultModel
    ): void => {
      setFieldValue('firstName', data.firstName);
      setFieldValue('lastName', data.lastName);
      /* istanbul ignore next */
      setFieldValue('middleName', data.middleName || '');
      setFieldValue('dateOfBirth', data.dateOfBirth);
      /* istanbul ignore next */
      setFieldValue('ssn', data.ssn || '');

      setFieldValue('hasMatch', true);
    };

    setSearching(true);
    setHasSearchMatch(false);
    setSearchMessage('');

    const response = await getStudentByMSISId(values.msisId);
    if (isValidCode(response.status)) {
      setUserFields(response.data);
      setHasSearchMatch(true);
    } else {
      // ANY Error Code
      setSearchMessage(
        'MSIS ID Not Found. Please ensure the MSIS ID has been created for the student.'
      );
    }

    setSearching(false);
  };

  const handleMsisValueChange = (): Promise<void> => {
    const resetMatchedFields = (): void => {
      setFieldValue('firstName', '');
      setFieldValue('lastName', '');
      setFieldValue('middleName', '');
      setFieldValue('dateOfBirth', '');
      setFieldValue('ssn', '');

      setFieldValue('hasMatch', false);

      setFieldTouched('firstName', false);
      setFieldTouched('lastName', false);
      setFieldTouched('birthDate', false);
    };

    setSearchMessage('');

    // Change after match
    if (hasSearchMatch) {
      resetMatchedFields();
      setHasSearchMatch(false);
    }

    return Promise.resolve();
  };

  return (
    <Panel panelType={PanelType.INFO} heading="Student Intervention">
      <div className="field-row field-row-msis-id">
        <div>
          <FormTextbox
            displayName="Enter MSIS ID"
            field="msisId"
            validationSchema={StudentInterventionValidationSchema}
            disabled={dataEntryMode === DataEntryMode.EDIT}
            onChangeEvent={handleMsisValueChange}
          />
        </div>
        <div className="search-button">
          <ActionButton
            classes="button--secondary"
            size={ButtonSize.SMALL}
            onClick={handleMSISSearch}
            disabled={searchDisabled}
            dataTestId="student-search-button"
            tooltipText="Click to search for student"
            loading={searching}
          >
            Search
          </ActionButton>
        </div>
        <div className="search-message" data-testid="search-error-message">
          {searchMessage}
        </div>
      </div>

      <div className="field-row field-row-msis-search-results">
        <FormTextbox
          displayName="Last Name"
          field="lastOrSurname"
          disabled={!hasSearchMatch || isStateNonAdmin()}
          validationSchema={StudentInterventionValidationSchema}
        />
        <FormTextbox
          displayName="First Name"
          field="firstName"
          disabled={!hasSearchMatch || isStateNonAdmin()}
          validationSchema={StudentInterventionValidationSchema}
        />
        <FormTextbox
          displayName="Middle Name"
          field="middleName"
          disabled={!hasSearchMatch || isStateNonAdmin()}
        />
        <FormDateSelector
          displayName="Date of Birth"
          field="birthDate"
          placeholderText=""
          disabled={!hasSearchMatch || isStateNonAdmin()}
          validationSchema={StudentInterventionValidationSchema}
        />
        <FormTextbox
          displayName="SSN"
          field="personIdentifierSsn"
          disabled={!hasSearchMatch || isStateNonAdmin()}
        />
      </div>
    </Panel>
  );
};

export default StudentIntervention_StudentInterventionPanel;
