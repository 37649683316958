/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import { getStudentInterventionListItems, getStudentInterventiontById, getStudentInterventionFormViewModel } from '../../services/studentIntervention.service';
import { StudentInterventionListItemModel } from '../../types/StudentInterventionListItemModel';
import { StudentInterventionModel } from 'src/types/StudentInterventionModel';
import { StudentInterventionFormViewModel } from 'src/types/StudentInterventionFormViewModel';

export const fetchStudentInterventionListItems = createAsyncThunk(
  'input/fetchStudentInterventionListItems',
  async (calendarYear: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      StudentInterventionListItemModel[]
    >(
      thunkAPI,
      getStudentInterventionListItems(calendarYear, { signal: thunkAPI.signal })
    );
  }
);

export const fetchStudentInterventionById = createAsyncThunk(
  'input/fetchStudentInterventionById',
  async (stateAgencyIdentifier: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<StudentInterventionModel>(
      thunkAPI,
      getStudentInterventiontById(stateAgencyIdentifier, {
        signal: thunkAPI.signal,
      })
    );
  }
);

export const fetchStudentInterventionFormViewModel = createAsyncThunk(
  'input/fetchStudentInterventionFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<StudentInterventionFormViewModel>(
      thunkAPI,
      getStudentInterventionFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
