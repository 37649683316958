import { Form, Formik } from 'formik';
import { noop } from 'lodash';
import { ReactElement } from 'react';
import { SingleValue } from 'react-select';
import { useAppDispatch } from '../../redux/hooks';
import { setDQDFilter } from '../../redux/slices/dataQualitySlice';
import { DataQualityFilterPropTypes } from '../../types/propTypes/DataQualityFilterPropTypes';
import { ReactSelectOption } from '../../types/ReactSelectOption';
import FormDropdown from '../FormInputs/FormDropdown/FormDropdown';
import './DataQualityFilter.css';

const DataQualityFilter = ({
  dataCollectionValue,
  dataCollectionOptions,
}: DataQualityFilterPropTypes): ReactElement => {
  const dispatch = useAppDispatch();

  const handleCollectionChange = (
    selected: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    /* istanbul ignore next */
    const getValue = selected?.value || '';

    dispatch(setDQDFilter(getValue));
    return Promise.resolve();
  };

  return (
    <div className="data-quality-filter-container">
      <Formik
        initialValues={{ dataCollectionName: dataCollectionValue }}
        onSubmit={
          /* istanbul ignore next */
          () => noop()
        }
      >
        <Form>
          <FormDropdown
            displayName="Certification Data Collection"
            field="dataCollectionName"
            options={dataCollectionOptions}
            onChange={handleCollectionChange}
            isClearable
          />
        </Form>
      </Formik>
    </div>
  );
};

export default DataQualityFilter;
