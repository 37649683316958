export const VALIDATION_MESSAGE = {
  REQUIRED: 'Field is required',
  MUST_SELECT_AT_LEAST: (size: number): string =>
    `Must select at least ${size} value`,
  MUST_SELECT_ONE: 'Must select a value',
  MUST_BE_NUMBER: 'Must be a number',
  MUST_BE_NUMBER_FORMAT:
    'Must be in this format 00000.00 without commas or $ symbol',
  AT_MOST_CHARS: (size: number): string => `Must be at most ${size} characters`,
  EXACT_NUMBER_LENGTH: (size: number): string =>
    `Must be exactly ${size} digits`,
  EXACT_LENGTH: (size: number): string => `Must be exactly ${size} characters`,
  NO_SPECIAL_CHARACTERS: 'No special characters allowed',
  ALPHANUMERIC: 'Letters and digits only',
  BEFORE_OR_EQUAL_TO_DATE: (displayName: string): string =>
    `Date must be after (or equal to) ${displayName}`,
  AFTER_OR_EQUAL_TO_DATE: (displayName: string): string =>
    `Date must be before (or equal to) ${displayName}`,
  BEFORE_DATE: (displayName: string): string =>
    `Date must be after ${displayName}`,
  MUST_BE_IN_SSN_FORMAT: 'Must be in the format XXX-XX-XXXX',
  EQUAL_TO_OR_EARLIER: 'Must be equal or earlier than today',
  MUST_NOT_BE_EQUAL_TO: (enteredValue: string): string =>
    `Must not be equal to ${enteredValue}`,
  MUST_BE_IN_PHONE_NUMBER_FORMAT: 'Must be in the format XXX-XXX-XXXX',
  ALPHANUMERIC_WITH_DASH: 'Letters, digits and dash only',
  MUST_BE_JUST_CHARACTERS: 'Must be characters only',
  MUST_HAVE_ALL_MSIS_PRIMARY_FIELDS:
    'Must enter all fields First Name, Last Name, Email, and MSIS primary phonenumber',
  MUST_BE_IN_VALID_EMAIL_FORMAT: 'Please enter valid email address',
  MUST_BE_IN_ZIP_CODE_FORMAT: 'Numbers and dashes only, min of 5 characters',
  MUST_BE_IN_BETWEEN_RANGE: (size: number, size2: number): string =>
    `Must be between ${size} and ${size2} characters`,
  MUST_BE_EXACT_NUMBER_LENGTH: (size: number): string =>
    `Must be ${size} digits`,
  IS_EMPTY: '',
};
