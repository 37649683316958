/* eslint-disable max-len */
import { StudentInterventionFormViewModel } from 'src/types/StudentInterventionFormViewModel';
import { StudentInterventionModel } from 'src/types/StudentInterventionModel';
import { StudentInterventionMsisidSearchResultModel } from 'src/types/StudentInterventionMsisidSearchResultModel';
import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { StudentInterventionListItemModel } from '../types/StudentInterventionListItemModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const studentInterventionApiPaths = {
  create: 'studentIntervention',
  update: 'studentIntervention',
  getSchoolYearListItems: 'studentIntervention/getSchoolYearListItems',
  getListItems: (schoolYear: string) =>
    `studentintervention?schoolYear=${schoolYear}`,
  getById: (studentInterventionId: string): string =>
    `studentIntervention/${studentInterventionId}`,
  getForm: 'studentIntervention/form',
  getStudentByMsisId: (msisId: string): string => `student/${msisId}`, // need to revert back to 'student/${msisId} later once the second panel is developed and endpoint return data' // nonPublicStudent/search
};

export const StudentInterventionServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const createStudentIntervention = async (
  body: StudentInterventionModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<string>> => {
  return executeApiRequest<string>(async () => {
    return await StudentInterventionServiceClient(config).post(
      studentInterventionApiPaths.create,
      body
    );
  });
};

export const updateStudentIntervention = async (
  body: StudentInterventionModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await StudentInterventionServiceClient(config).put(
      studentInterventionApiPaths.update,
      body
    );
  });
};

export const getStudentInterventiontById = async (
  studentInterventionId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<StudentInterventionModel>> => {
  return executeApiRequest<StudentInterventionModel>(async () => {
    return await StudentInterventionServiceClient(config).get(
      studentInterventionApiPaths.getById(studentInterventionId)
    );
  });
};

export const getStudentInterventionListItems = async (
  calendarYear: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<StudentInterventionListItemModel[]>> => {
  return executeApiRequest<StudentInterventionListItemModel[]>(async () => {
    return await StudentInterventionServiceClient(config).get(
      studentInterventionApiPaths.getListItems(calendarYear)
    );
  });
};

export const getStudentInterventionFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<StudentInterventionFormViewModel>> => {
  return executeApiRequest<StudentInterventionFormViewModel>(async () => {
    return await StudentInterventionServiceClient(config).get(
      studentInterventionApiPaths.getForm
    );
  });
};

export const getStudentByMSISId = async (
  msisId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<StudentInterventionMsisidSearchResultModel>> => {
  return executeApiRequest<StudentInterventionMsisidSearchResultModel>(
    async () => {
      return await StudentInterventionServiceClient(config).get(
        studentInterventionApiPaths.getStudentByMsisId(msisId)
      );
    }
  );
};
