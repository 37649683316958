import { ReactElement } from 'react';
import { ErrorDetailsPropTypes } from '../../types/propTypes/ErrorDetailsPropTypes';
import './ErrorDetails.css';
import Parse from 'html-react-parser';

const ErrorDetails = ({
  title,
  subtitle,
  instructionsToFix,
  dataCy,
}: ErrorDetailsPropTypes): ReactElement => (
  <div className="error-details" data-cy={dataCy}>
    <div className="error-details--header">{title}</div>
    <div className="error-details--subheader">{Parse(subtitle)}</div>
    {instructionsToFix && (
      <>
        <div className="error-details--header error-details--instructions">
          How to fix this problem:
        </div>
        {instructionsToFix}
      </>
    )}
  </div>
);

export default ErrorDetails;
