import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  isValidCode,
  thunkExecuteAndReturnResultOrShowError,
} from '../../services/base.service';
import {
  download,
  getFileById,
  getFileList,
  getFilePermissionsMap,
  getUploadFormModel,
} from '../../services/file.service';
import { DownloadModel } from '../../types/DownloadModel';
import { FileDetailsModel } from '../../types/FileDetailsModel';
import { FilePermissionMap } from '../../types/FilePermissionMap';
import { FilesListModel } from '../../types/FilesListModel';
import { FileUploadFormModel } from '../../types/FileUploadFormModel';
import { createDownloadLinkAndExecute } from '../../utilities/formUtilities';
import { errorLoaded } from '../slices/errorSlice';

export const fetchFiles = createAsyncThunk(
  'file/fetchFiles',
  async (
    params: {
      leaId: string;
      fileStatus: string;
      uploadedBy: string;
      startDate: string;
      endDate: string;
    },
    thunkAPI
  ) => {
    const { leaId, fileStatus, uploadedBy, startDate, endDate } = params;
    return await thunkExecuteAndReturnResultOrShowError<FilesListModel>(
      thunkAPI,
      getFileList(leaId, fileStatus, uploadedBy, startDate, endDate, {
        signal: thunkAPI.signal,
      })
    );
  }
);

export const fetchFileById = createAsyncThunk(
  'file/fetchFileById',
  async ({ fileId, leaId }: { fileId: string; leaId: string }, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<FileDetailsModel>(
      thunkAPI,
      getFileById(fileId, leaId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchUploadFormModel = createAsyncThunk(
  'file/fetchUploadFormModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<FileUploadFormModel>(
      thunkAPI,
      getUploadFormModel({ signal: thunkAPI.signal })
    );
  }
);

export const fetchPermissionsMap = createAsyncThunk(
  'file/fetchPermissions',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<FilePermissionMap[]>(
      thunkAPI,
      getFilePermissionsMap({ signal: thunkAPI.signal })
    );
  }
);

export const downloadFileById = createAsyncThunk(
  'file/downloadFileById',
  async (details: DownloadModel, thunkAPI) => {
    // Corrected object destructuring in the download function call
    const response = await download(details.fileId, details.leaId, {
      signal: thunkAPI.signal,
    });
    if (isValidCode(response.status)) {
      createDownloadLinkAndExecute(
        details.fileName ?? '',
        response.data as unknown as Blob
      );
    } else {
      thunkAPI.dispatch(errorLoaded(response.error));
    }
  }
);
