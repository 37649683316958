/* eslint-disable max-len */
import {
  SOLUTION_FAILURE,
  SOLUTION_SUCCESS,
} from '../components/FileUpload/UploadConstants';
import { ValidationResults } from '../types/ValidationResult';

export const getFormatedListOfFileEndings = (fileEndings: string[]): string => {
  return fileEndings.reduce((acc, cur, index, arry) => {
    if (index === 0) {
      return acc + `\"${cur}\"`;
    }
    if (index === arry.length - 1) {
      return acc + ' and ' + `\"${cur}\"`;
    }
    return acc + ', ' + `\"${cur}\"`;
  }, '');
};

export const IsValidFileEnding = (
  fileName: string,
  fileEndings: string[]
): boolean => {
  let isValid = false;
  const fileNameWithoutExtension = fileName.substring(0, fileName.length - 4);

  fileEndings.forEach((ending: string) => {
    if (!isValid && fileNameWithoutExtension.endsWith(ending)) {
      isValid = true;
    }
  });
  return isValid;
};

export const validateFiles = (rawFiles: File[]): ValidationResults => {
  const validationResult: ValidationResults = {
    validFiles: [],
    invalidFiles: [],
    errorMessages: [],
    solutions: [],
  };

  const rawFileArray = [...rawFiles];
  const errorsComplete = false;

  validationResult.validFiles = rawFileArray.filter(() => {
    const allowed = true;

    return allowed;
  });

  // If errorsComplete is true, then we've had an LEA ID error and don't need to add other errors.
  if (errorsComplete) {
    return validationResult;
  }

  if (
    validationResult.invalidFiles.length > 0 &&
    validationResult.validFiles.length === 0
  ) {
    validationResult.solutions.push(SOLUTION_FAILURE);
  }
  if (
    validationResult.invalidFiles.length > 0 &&
    validationResult.validFiles.length > 0
  ) {
    validationResult.solutions.push(SOLUTION_FAILURE);
    validationResult.solutions.push(SOLUTION_SUCCESS);
  }

  return validationResult;
};
