import { ReactElement } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { CourseCodeFormViewModel } from 'src/types/CourseCodeFormViewModel';
import FormCheckboxList from '../../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import FormDropdown from '../../../components/FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../components/FormInputs/FormRadioList/FormRadioList';
import Panel from '../../../components/Panel/Panel';
import { selectCourseCodeFormViewModel } from '../../../redux/slices/courseCodeSlice';
import { PanelType } from '../../../types/PanelType';
import { FormRadioListOrientation } from '../../../types/propTypes/FormRadioListPropTypes';
import { CourseCodeValidationSchema } from '../../../types/Validation/CourseCodeValidationSchema';
import { isStateAdmin } from '../../../utilities/userUtilities';

const CourseCodeCertificationAndFundingPanel = (): ReactElement => {
  const formViewModel: CourseCodeFormViewModel = useAppSelector(
    selectCourseCodeFormViewModel
  );
  return (
    <Panel panelType={PanelType.INFO} heading="Certification & Funding">
      <div className="field-row field-row-grid-1-3">
        <FormRadioList
          displayName="License Required"
          field="certificateRequired"
          options={formViewModel.certificateRequiredOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
        <FormDropdown
          displayName="Specific Endorsements"
          field="specificEndorsements"
          isMulti={true}
          options={formViewModel.specificEndorsementOptions.map((option) => ({
            ...option,
            text: `${option.value} ${option.text}`,
          }))}
          containerClasses="specific-endorsement-container"
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
      <div className="field-row">
        <FormCheckboxList
          displayName="Funding Codes"
          field="courseFundingProgram"
          options={formViewModel.courseFundingProgramOptions}
          classConfig={{ containerClasses: 'funding-codes-container' }}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
    </Panel>
  );
};

export default CourseCodeCertificationAndFundingPanel;
