import { useFormikContext } from 'formik';
import React, { ReactElement, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { CourseCodeFormViewModel } from 'src/types/CourseCodeFormViewModel';
import { CourseCodeModel } from 'src/types/CourseCodeModel';
import FormDropdown from '../../../components/FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../components/FormInputs/FormRadioList/FormRadioList';
import Panel from '../../../components/Panel/Panel';
import { selectCourseCodeFormViewModel } from '../../../redux/slices/courseCodeSlice';
import { PanelType } from '../../../types/PanelType';
import { FormRadioListOrientation } from '../../../types/propTypes/FormRadioListPropTypes';
import { CourseCodeValidationSchema } from '../../../types/Validation/CourseCodeValidationSchema';
import { isStateAdmin } from '../../../utilities/userUtilities';

const CourseCodeCteOnlyPanel = (): ReactElement => {
  const { setFieldValue } = useFormikContext<CourseCodeModel>();
  const [yearIndicator, setYearIndicator] = useState('');
  const formViewModel: CourseCodeFormViewModel = useAppSelector(
    selectCourseCodeFormViewModel
  );
  const handleYearIndicator = (config: React.ChangeEvent<HTMLInputElement>): void => {
    if (config.target.name === 'sequenceOfCourse') {
      if (yearIndicator === config.target.value) {
        setFieldValue('sequenceOfCourse', '');
      } else {
        setYearIndicator(config.target.value);
      }
    }
  };
  return (
    <Panel panelType={PanelType.INFO} heading="CTE Only">
      <div className="field-row">
        <FormRadioList
          displayName="Year Indicator"
          field="sequenceOfCourse"
          options={formViewModel.sequenceOfCourseOptions}
          optionOrientation={FormRadioListOrientation.Horizontal}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
          onClick={handleYearIndicator}
        />
      </div>

      <div className="field-row field-row-grid-1-1">
        <FormDropdown
          displayName="CIP Code"
          field="cipCode"
          options={formViewModel.cipCodeOptions}
          validationSchema={CourseCodeValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
    </Panel>
  );
};

export default CourseCodeCteOnlyPanel;
