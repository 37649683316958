/* eslint-disable max-len */

export const baseAppRoutes = {
  Accreditation: '/accreditation',
  DataQuality: '/data-quality',
  MSIS_ID: '/msisidquery',
  LEASchoolInput: '/input/district-school-info',
  OTSSInput: '/input/mde-otss',
  StudentInput: '/input/student',
  StudentOwnership: '/student-owner',
  Reports: '/report',
  Files: '/file',
  User: '/user',
  Upload: '/upload',
};
/* istanbul ignore next */

export const appRoutePaths = {
  Dashboard: '/',
  Login: '/login',
  NewUser: '/welcome',
  GetToken: '/get-token',
  Logout: '/logout',
  ShowToken: '/show-token',
  Feedback: '/feedback',
  SubmissionStatus: `${baseAppRoutes.Files}`,
  DataQualityDashboard: `${baseAppRoutes.DataQuality}`,
  Accreditation: `${baseAppRoutes.Accreditation}`,
  AccreditationEdits: (category = ':category') =>
    `${baseAppRoutes.Accreditation}/${category}`,
  DataQualityCategory: (category = ':category') =>
    `${baseAppRoutes.DataQuality}/${category}`,
  DataQualityDetail: (category = ':category', errorLevel = ':errorLevel') =>
    `${baseAppRoutes.DataQuality}/${category}/${errorLevel}`,
  FileUpload: `${baseAppRoutes.Upload}/file`,
  FileDetail: (fileId = ':fileId') => `${baseAppRoutes.Files}/${fileId}`,
  MSISRequest: `${baseAppRoutes.MSIS_ID}`,
  MSISRequestNew: `${baseAppRoutes.MSIS_ID}/new`,
  MSISRequestDetail: (requestId = ':requestId') =>
    `${baseAppRoutes.MSIS_ID}/${requestId}`,
  StudentOwnership: `${baseAppRoutes.StudentOwnership}`,
  StudentOwnershipDetail: (ownerConflictId = ':ownerConflictId') =>
    `${baseAppRoutes.StudentOwnership}/${ownerConflictId}`,
  ReportDashboard: `${baseAppRoutes.Reports}`,
  ReportDisplay: (reportId = ':reportId') =>
    `${baseAppRoutes.Reports}/${reportId}`,
  CharterSchoolAuthorizingOrganizationList: `${baseAppRoutes.LEASchoolInput}/charter-school/authorizing-organization`,
  LEAManagementList: `${baseAppRoutes.LEASchoolInput}/lea-management`,
  LEAManagementInput: (leaId = ':leaId') =>
    `${baseAppRoutes.LEASchoolInput}/lea-management/${leaId}`,
  NewCharterSchoolAuthorizingOrganization: `${baseAppRoutes.LEASchoolInput}/charter-school/authorizing-organization/new`,
  NewCharterSchoolManagementOrganization: `${baseAppRoutes.LEASchoolInput}/charter-school/management-organization/new`,
  NewCharterSchoolAssignment: `${baseAppRoutes.LEASchoolInput}/charter-school/assignment/new`,
  SchoolManagementList: `${baseAppRoutes.LEASchoolInput}/school-management`,
  SchoolManagementInput: (schoolIdentifier = ':schoolIdentifier') =>
    `${baseAppRoutes.LEASchoolInput}/school-management/${schoolIdentifier}`,
  LEASharingList: `${baseAppRoutes.LEASchoolInput}/lea-sharing`,
  LEASharingInput: (leaId = ':leaId') =>
    `${baseAppRoutes.LEASchoolInput}/lea-sharing/${leaId}`,
  SalaryScaleList: `${baseAppRoutes.LEASchoolInput}/salary-scale`,
  SeaOrganizationList: `${baseAppRoutes.OTSSInput}/sea-organization`,
  SeaOrganizationInput: (seaOrganizationId = ':seaOrganizationId') =>
    `${baseAppRoutes.OTSSInput}/sea-organization/${seaOrganizationId}`,
  CourseCodeList: `${baseAppRoutes.OTSSInput}/course-code`,
  CourseCodeInput: (courseCodeId = ':courseCodeId') =>
    `${baseAppRoutes.OTSSInput}/course-code/${courseCodeId}`,
  NonPublicStudentEntryList: `${baseAppRoutes.StudentInput}/non-public-student-entry`,
  NonPublicStudentEntryInput: (nonPublicStudentId = ':nonPublicStudentId') =>
    `${baseAppRoutes.StudentInput}/non-public-student-entry/${nonPublicStudentId}`,
  NonPublicStudentEntryScheduleInput: (
    nonPublicStudentId = ':nonPublicStudentId',
    scheduleId = ':scheduleId'
  ) =>
    `${baseAppRoutes.StudentInput}/non-public-student-entry/${nonPublicStudentId}/schedule/${scheduleId}`,
  SummerActivityList: `${baseAppRoutes.StudentInput}/summer-activity`,
  SummerActivityInput: (summerActivityId = ':summerActivityId') =>
    `${baseAppRoutes.StudentInput}/summer-activity/${summerActivityId}`,
  StudentInterventionList: `${baseAppRoutes.StudentInput}/student-intervention`,
  StudentInterventionInput: (
    studentInterventionId = ':studentInterventionId'
  ) => `${baseAppRoutes.StudentInput}/student-intervention/${studentInterventionId}`,
  UserList: `${baseAppRoutes.User}`,
  UserDetail: (userId = ':userId') => `${baseAppRoutes.User}/${userId}`,
  UserEdit: (userId = ':userId') => `${baseAppRoutes.User}/edit/${userId}`,
};
