/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import DataQualityCard from '../../components/DashboardCards/DataQualityCard/DataQualityCard';
import StudentOwnershipCard from '../../components/DashboardCards/StudentOwnershipCard/StudentOwnershipCard';
import FailedFilesCard from '../../components/DashboardCards/FailedFilesCard/FailedFilesCard';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectDashboardLoading,
  selectHasConflicts,
} from '../../redux/slices/dashboardSlice';
import { selectUserName } from '../../redux/slices/loginSlice';
import { fetchDashboardData } from '../../redux/thunks/dashboardThunks';
import {
  abortPromiseOnUnmount,
  getAuthToken,
  getStoredUserName,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { canUserAccessRoute } from '../../services/security.service';
import {
  hasRoleExcludingAdmin,
  isNewUser,
} from '../../utilities/userUtilities';
import './Dashboard.css';

export const Dashboard = (): ReactElement => {
  const dispatch = useAppDispatch();
  const token = getAuthToken();
  const selectedName = useAppSelector(selectUserName);
  const hasConflicts = useAppSelector(selectHasConflicts);
  const isLoading = useAppSelector(selectDashboardLoading);

  const name = selectedName.length === 0 ? getStoredUserName() : selectedName;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const formattedTime = currentDate.toLocaleTimeString('en-us', {
    timeStyle: 'short',
  });
  const hasSomeDashboardDataAccess = (): boolean =>
    canUserAccessRoute(appRoutePaths.SubmissionStatus) ||
    canUserAccessRoute(appRoutePaths.StudentOwnership) ||
    canUserAccessRoute(appRoutePaths.DataQualityDashboard);

  useEffect(() => {
    let promise: unknown = undefined;
    const shouldGetDashboardData = (): boolean =>
      !isNewUser() && hasRoleExcludingAdmin() && hasSomeDashboardDataAccess();

    if (token && shouldGetDashboardData()) {
      promise = dispatch(fetchDashboardData());
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, token]);

  return (
    <div className="dashboard">
      <h2 className="page--heading">Welcome {name}</h2>
      <time>
        <span>{formattedTime}</span> {formattedDate}
      </time>
      {isLoading && (
        <section className="dashboard--grid--loader">
          <Loader
            dataTestId="file-status-loader"
            message="Getting your alerts..."
          />
        </section>
      )}
      {token && !isLoading && (
        <div className="dashboard--grid page--grid">
          <>
            {
              <>
                {canUserAccessRoute(appRoutePaths.SubmissionStatus) && (
                  <FailedFilesCard />
                )}
                {canUserAccessRoute(appRoutePaths.StudentOwnership) &&
                  hasConflicts && <StudentOwnershipCard />}
                {canUserAccessRoute(appRoutePaths.DataQualityDashboard) && (
                  <DataQualityCard />
                )}
              </>
            }
            {/* TODO: Bring back DeadlinesCard in the future, once product ownership calls for it. */}
            {/* <DeadlinesCard /> */}
          </>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
