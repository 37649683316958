import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const StudentInterventionValidationSchema = yup.object().shape({
  msisId: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .mustBeNumber()
    .min(9, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(9))
    .max(9, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(9)),
  lastOrSurname: yup.string(),
  firstName: yup.string(),
  birthDate: yup.string(),
});
