/* eslint-disable max-len */
import { getReportList } from '../services/base.service';
import {
  ReportReference,
  ResolvedReportList,
} from '../types/CertificationDetailModel';
import { ReportEmbedData } from '../types/ReportEmbedData';
import { ReportListModel } from '../types/ReportListModel';

export const resolveReports = (
  references: ReportReference[]
): ResolvedReportList => {
  const errorListItems: string[] = [];
  const reportItems: ReportEmbedData[] = [];
  let reportDisciplineNotFoundError = false;

  const addReportFromReferenceOrError = (
    reportRef: ReportReference,
    reportList?: ReportListModel
  ): void => {
    let reportFound = false;
    const cat = reportList?.categories.find(
      (x) => x.name === reportRef.categoryName
    );
    if (cat) {
      const sub = cat.subcategories.find(
        (sc) => sc.name === reportRef.subcategoryName
      );
      if (sub) {
        const report = sub.reports.find(
          (r) => r.reportName === reportRef.reportName
        );
        if (report) {
          reportFound = true;
          reportItems.push(report);
        }
      }
    }

    if (!reportFound) {
      if(reportRef.reportName.toLowerCase().indexOf('discipline') > -1){
        reportDisciplineNotFoundError = true;
      }

      const reportInfoString =
        reportRef.subcategoryName === ''
          ? `(Category: ${reportRef.categoryName})`
          : `(Category: ${reportRef.categoryName} & Subcategory: ${reportRef.subcategoryName})`;
      errorListItems.push(`${reportRef.reportName} ${reportInfoString}`);
    }
  };

  references.map((ref) => addReportFromReferenceOrError(ref, getReportList()));
  const disciplineError = 'The Discipline report requires Discipline Data Domain permissions. Ensure you have appropriate permission. Reach out to your MSIS Primary or Secondary, if needed.<br><br>';
  const errorMessage =
    errorListItems.length > 0
    ? (reportDisciplineNotFoundError ? disciplineError : '') + `Report(s) associated with this data collection cannot be found: ${errorListItems.join(
          ', '
        )}`
      : '';

  return { reports: reportItems, errors: errorMessage };
};
