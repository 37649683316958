/* eslint-disable max-len */
import { useField, useFormikContext } from 'formik';
import { find } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { fetchNonPublicStudentListItems } from 'src/redux/thunks/nonPublicStudentThunks';
import { abortPromiseOnUnmount } from 'src/services/base.service';
import { NonPublicStudentFormViewModel } from 'src/types/NonPublicStudentFormViewModel';
import { NonPublicStudentListItemModel } from 'src/types/NonPublicStudentListItemModel';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectNonPublicStudentDataEntryMode,
  selectNonPublicStudentFormViewModel,
  selectNonPublicStudentListItems,
  selectNonPublicStudentNonPublicStudent,
  selectSchoolYear,
} from '../../../redux/slices/nonPublicStudentSlice';
import {
  getCurrentUserAccess,
  isValidCode,
} from '../../../services/base.service';
import { getStudentByMSISId } from '../../../services/nonPublicStudent.service';
import { ButtonSize } from '../../../types/ButtonSize';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { NonPublicStudentEntryModel } from '../../../types/NonPublicStudentEntryModel';
import { NonPublicStudentSearchResultModel } from '../../../types/NonPublicStudentSearchResultModel';
import { PanelType } from '../../../types/PanelType';
import { NonPublicStudentEntryValidationSchema } from '../../../types/Validation/NonPublicStudentEntryValidationSchema';
import { isStateNonAdmin } from '../../../utilities/userUtilities';
import ActionButton from '../../ActionButton/ActionButton';
import FormDateSelector from '../../FormInputs/FormDateSelector/FormDateSelector';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';
import './NPSE_StudentPanel.css';

const NPSE_StudentPanel = (): ReactElement => {
  const { values, setFieldValue, setFieldTouched, dirty } =
    useFormikContext<NonPublicStudentEntryModel>();
  const dispatch = useAppDispatch();

  const [, msisIdMetaProps] = useField('studentIdentifier');
  const getIsMsisIDValid = (): boolean => !msisIdMetaProps?.error;

  const dataEntryMode: DataEntryMode = useAppSelector(
    selectNonPublicStudentDataEntryMode
  );

  const listItem: NonPublicStudentListItemModel[] = useAppSelector(
    selectNonPublicStudentListItems
  );

  const formView: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );

  const npsInput: NonPublicStudentEntryModel = useAppSelector(
    selectNonPublicStudentNonPublicStudent
  );
  const newModeSchoolYear = useAppSelector(selectSchoolYear);

  const getDistrictId =
    getCurrentUserAccess()?.currentDistrict?.districtId || '';

  const hasMatch: boolean =
    dataEntryMode === DataEntryMode.EDIT || values.firstName.length > 0;

  const [searching, setSearching] = useState(false);
  const [hasSearchMatch, setHasSearchMatch] = useState(hasMatch);
  const [searchMessage, setSearchMessage] = useState('');

  const isMsisIdAndSchoolTypeNotEmpty: boolean =
    values.studentIdentifier !== '' && values.schoolType !== '';

  useEffect(() => {
    let promise: unknown;
    let schoolYear = '';

    if (DataEntryMode.NEW) {
      schoolYear = newModeSchoolYear || formView.defaultSchoolYear;
    } else {
      schoolYear = npsInput.schoolYear;
    }

    if (schoolYear) {
      promise = dispatch(fetchNonPublicStudentListItems(schoolYear));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const currentDistrictSchools = find(formView.districts, {
      districtId: getDistrictId,
    });
    const is200CteSchoolExists = find(currentDistrictSchools?.schools, {
      schoolId: `${getDistrictId}-200`,
    });
    const is500SpedSchoolExists = find(currentDistrictSchools?.schools, {
      schoolId: `${getDistrictId}-500`,
    });

    if (
      (dataEntryMode === DataEntryMode.NEW ||
        dataEntryMode === DataEntryMode.EDIT) &&
      isMsisIdAndSchoolTypeNotEmpty
    ) {
      const listItemRecord: NonPublicStudentListItemModel | undefined = find(
        listItem,
        {
          studentIdentifier: values.studentIdentifier,
          schoolType: values.schoolType,
        }
      );

      if (
        dirty &&
        npsInput.schoolType !== values.schoolType &&
        listItemRecord !== undefined
      ) {
        setSearchMessage(
          'A student with this MSIS ID already has an entry.  Please check the MSIS ID and try again.'
        );

        setFieldValue('schoolTypeInitial', '');
      } else {
        setSearchMessage('');
      }
    }

    if (values.schoolType === 'CTE' && is200CteSchoolExists === undefined) {
      setSearchMessage(
        'School 200 does not exist in your LEA. Please contact the MSIS helpdesk.'
      );
    }

    if (values.schoolType === 'SPED' && is500SpedSchoolExists === undefined) {
      setSearchMessage(
        'School 500 does not exist in your LEA. Please contact the MSIS helpdesk.'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.schoolType, values.studentIdentifier]);

  const searchDisabled =
    dataEntryMode === DataEntryMode.EDIT ||
    !getIsMsisIDValid() ||
    searching ||
    hasSearchMatch;

  const handleMSISSearch = async (): Promise<void> => {
    const setUserFields = (data: NonPublicStudentSearchResultModel): void => {
      setFieldValue('firstName', data.firstName);
      setFieldValue('lastOrSurname', data.lastOrSurname);
      /* istanbul ignore next */
      setFieldValue('middleName', data.middleName || '');
      setFieldValue('birthDate', data.birthDate);
      /* istanbul ignore next */
      setFieldValue('personIdentifierSsn', data.personIdentifierSsn || '');

      setFieldValue('hasMatch', true);
    };

    setSearching(true);
    setHasSearchMatch(false);
    setSearchMessage('');

    const response = await getStudentByMSISId(values.studentIdentifier);
    if (isValidCode(response.status)) {
      setUserFields(response.data);
      setHasSearchMatch(true);
      setFieldValue('ideaEducationalEnvironment', '');
    } else {
      // ANY Error Code
      setSearchMessage(
        'MSIS ID Not Found. Please ensure the MSIS ID has been created for the student.'
      );
    }

    setSearching(false);
  };

  const handleMsisValueChange = (): Promise<void> => {
    const resetMatchedFields = (): void => {
      setFieldValue('firstName', '');
      setFieldValue('lastOrSurname', '');
      setFieldValue('middleName', '');
      setFieldValue('birthDate', '');
      setFieldValue('personIdentifierSsn', '');

      setFieldValue('hasMatch', false);

      setFieldTouched('firstName', false);
      setFieldTouched('lastOrSurname', false);
      setFieldTouched('birthDate', false);
    };

    setSearchMessage('');

    // Change after match
    if (hasSearchMatch) {
      resetMatchedFields();
      setHasSearchMatch(false);
    }

    return Promise.resolve();
  };

  return (
    <Panel panelType={PanelType.INFO} heading="Student Information">
      <div className="field-row field-row-msis-id">
        <div>
          <FormTextbox
            displayName="Enter MSIS ID"
            field="studentIdentifier"
            validationSchema={NonPublicStudentEntryValidationSchema}
            disabled={dataEntryMode === DataEntryMode.EDIT}
            onChangeEvent={handleMsisValueChange}
          />
        </div>
        <div className="search-button">
          <ActionButton
            classes="button--secondary"
            size={ButtonSize.SMALL}
            onClick={handleMSISSearch}
            disabled={searchDisabled}
            dataTestId="student-search-button"
            tooltipText="Click to search for student"
            loading={searching}
          >
            Search
          </ActionButton>
        </div>
        <div className="search-message" data-testid="search-error-message">
          {searchMessage}
        </div>
      </div>

      <div className="field-row field-row-msis-search-results">
        <FormTextbox
          displayName="Last Name"
          field="lastOrSurname"
          disabled={!hasSearchMatch || isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormTextbox
          displayName="First Name"
          field="firstName"
          disabled={!hasSearchMatch || isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormTextbox
          displayName="Middle Name"
          field="middleName"
          disabled={!hasSearchMatch || isStateNonAdmin()}
        />
        <FormDateSelector
          displayName="Date of Birth"
          field="birthDate"
          placeholderText=""
          disabled={!hasSearchMatch || isStateNonAdmin()}
          validationSchema={NonPublicStudentEntryValidationSchema}
        />
        <FormTextbox
          displayName="SSN"
          field="personIdentifierSsn"
          disabled={!hasSearchMatch || isStateNonAdmin()}
        />
      </div>
    </Panel>
  );
};

export default NPSE_StudentPanel;
