/* eslint-disable max-len */
import { Box, Typography } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import {
  DateRange,
  DateRangeValidationError,
} from '@mui/x-date-pickers-pro/models';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import ActionButton from '../../components/ActionButton/ActionButton';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import Pill from '../../components/Pill/Pill';
import StyledSingleDropdown from '../../components/StyledSingleDropdown/StyledSingleDropdown';
import { FileStatus } from '../../generated';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectSelectedDistrict } from '../../redux/slices/appSlice';
import { selectFileLoading, selectFiles } from '../../redux/slices/fileSlice';
import { downloadFileById, fetchFiles } from '../../redux/thunks/filesThunks';
import { TooltipAnchor } from '../../services/app.service';
import {
  abortPromiseOnUnmount,
  isValidCode,
} from '../../services/base.service';
import { deleteFile } from '../../services/file.service';
import { appRoutePaths } from '../../services/route.service';
import {
  canExecuteAppAction,
  canUserAccessRoute,
} from '../../services/security.service';
import { PillType } from '../../types/PillType';
//import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { isStateNonAdmin } from 'src/utilities/userUtilities';
import FileDetailPage from '../../components/FileDetailPage/FileDetailPage';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { AppActions } from '../../types/AppActions';
import { FileModel, getFileStatusList } from '../../types/FileModel';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import { ReactSelectOption } from '../../types/ReactSelectOption';
import { formatToDateTimeString } from '../../utilities/dateUtilities';
import {
  parseFilename,
  setStyledDropdownSingleValue,
} from '../../utilities/helperUtilities';
import { prependLastword } from '../../utilities/textUtilities';
import './Files.css';

const Files = (): ReactElement => {
  const confirmContext = useConfirmationModalContext();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectFileLoading);
  const { districtId } = useAppSelector(selectSelectedDistrict);
  const files: FileModel[] = useAppSelector(selectFiles);
  const selectedDistrict = useAppSelector(selectSelectedDistrict);
  const [error, setError] = useState<DateRangeValidationError>([null, null]);
  const [filterStatus, setFilterStatus] = useState<string>('');
  const [filterUploadedBy, setFilterUploadedBy] = useState<string>('');

  const defaultEndDate = dayjs();
  const defaultStartDate = dayjs().subtract(30, 'day');
  const schoolYearStartDate = dayjs().subtract(365, 'day');
  const schoolYearEndDate = dayjs();
  const defaultFilterStatus = '';
  const defaultFlterUploadedBy = '';

  const [dateRangeValue, setDateRangeValue] = useState<DateRange<Dayjs>>([
    defaultStartDate,
    defaultEndDate,
  ]);

  useEffect(() => {
    //let promise: unknown;
    const promise = dispatch(
      fetchFiles({
        leaId: districtId,
        fileStatus: defaultFilterStatus,
        uploadedBy: defaultFlterUploadedBy,
        startDate: defaultStartDate.format('YYYY-MM-DD'),
        endDate: defaultEndDate.format('YYYY-MM-DD'),
      })
    );
    return () => {
      abortPromiseOnUnmount(promise);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, districtId]);

  const handleStatusChange = async (
    props: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    /* istanbul ignore next */
    const status = props?.value || defaultFilterStatus;
    setFilterStatus(status);
  };

  const handleChange = (newValue: DateRange<Dayjs>): void => {
    if(newValue[0] === null || !newValue[0].isValid()){
        setError(['invalidDate', null]);
    }
    else if(newValue[1] === null || !newValue[1].isValid()){
        setError([null,'invalidDate']);
    }
    else{
      setError([null, null]);
    }

    setDateRangeValue(newValue);
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [_, end] = newValue;
    const today = dayjs();

    if (end && end.isAfter(today)) {
      setError([null, 'disableFuture']);
    } else if (
      end &&
      !end.isAfter(today) &&
      error &&
      error[1] === 'disableFuture'
    ) {
      setError([null, null]);
    }
  };

  const errorMessage = React.useMemo(() => {
    let startErrorMessage = '';
    switch (error[0]) {
      case 'invalidDate': {
        startErrorMessage = 'Start date is invalid.';
        break;
      }
      case 'invalidRange': {
        startErrorMessage = 'Start date cannot be greater than end date.';
        break;
      }
      case 'minDate': {
        startErrorMessage = `Start & end date should be with in ${schoolYearStartDate.format(
          'MM/DD/YYYY'
        )} - ${schoolYearEndDate.format('MM/DD/YYYY')}.`;
        break;
      }
      case 'maxDate': {
        startErrorMessage = `Start & end date should be with in ${schoolYearStartDate.format(
          'MM/DD/YYYY'
        )} - ${schoolYearEndDate.format('MM/DD/YYYY')}.`;
        break;
      }
    }

    let endErrorMessage = '';
    switch (error[1]) {
      case 'invalidDate': {
        endErrorMessage = 'End date is invalid';
        break;
      }
      case 'invalidRange': {
        endErrorMessage = 'End date cannot be less than start date.';
        break;
      }
      case 'minDate': {
        endErrorMessage = `Start & end date should be with in ${schoolYearStartDate.format(
          'MM/DD/YYYY'
        )} - ${schoolYearEndDate.format('MM/DD/YYYY')}.`;
        break;
      }
      case 'maxDate': {
        endErrorMessage = `Start & end date should be with in ${schoolYearStartDate.format(
          'MM/DD/YYYY'
        )} - ${schoolYearEndDate.format('MM/DD/YYYY')}.`;
        break;
      }
      case 'disableFuture': {
        endErrorMessage = 'End Date cannot exceed the current date';
        break;
      }
    }
    return startErrorMessage || endErrorMessage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleSubmit = (): void => {
    dispatch(
      fetchFiles({
        leaId: districtId,
        fileStatus: filterStatus,
        uploadedBy: filterUploadedBy,
        startDate: dateRangeValue[0]?.format('YYYY-MM-DD') || defaultStartDate.format('YYYY-MM-DD'),
        endDate: dateRangeValue[1]?.format('YYYY-MM-DD') || defaultEndDate.format('YYYY-MM-DD'),
      })
    );
  };

  const filterDropdown = (
    <>
      <div className="container">
        <div className="status-filter">
          <StyledSingleDropdown
            fieldName="statusFilter"
            showLabel={true}
            labelText="Status"
            onChange={handleStatusChange}
            options={getFileStatusList()}
            isLoading={isLoading}
            isClearable = {true}
            value={setStyledDropdownSingleValue(
              getFileStatusList(),
              filterStatus
            )}
          />
        </div>
        <div className="uploaded-by-filter">
          <div className="field-label">Uploaded By</div>
          <MuiTextField
            defaultValue={defaultFlterUploadedBy}
            id="uploadedByFilter"
            type="text"
            name="uploadedByFilter"
            label="Enter uploaded by name"
            value={filterUploadedBy}
            onChange={(e) => {
              setFilterUploadedBy(e.target.value || defaultFlterUploadedBy);
            }}
          />
        </div>
        <div>
          <Typography
            variant="inherit"
            sx={{ mt: 2, width: 500, pl: 2 }}
            gutterBottom
          >
            Select start and end dates (within the previous 365 days), then
            select Apply Filter.
          </Typography>

          <div>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: '', end: '' }}
            >
              <Box sx={{ width: 500, padding: 2 }}>
                <DateRangePicker
                  value={dateRangeValue}
                  defaultValue={[defaultStartDate, defaultEndDate]}
                  minDate={schoolYearStartDate}
                  maxDate={schoolYearEndDate}
                  onChange={(newValue) => handleChange(newValue)}
                  onError={([startError, endError]) =>
                    setError([startError, endError])
                  }
                />
                <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
              </Box>
            </LocalizationProvider>
          </div>
        </div>
        <div>
          <ActionButton
            onClick={handleSubmit}
            classes="no-wrap-text apply-filter"
            tooltipText="apply filter"
            disabled={errorMessage === '' ? false : true}
          >
            <> Apply Filter</>
          </ActionButton>
        </div>
      </div>
    </>
  );

  const handleFileUpload = async (
    logId: string,
    fileName: string | undefined,
    leaId: string
  ): Promise<void> => {
    dispatch(downloadFileById({ fileId: logId, fileName, leaId }));
  };

  const UploadButton = (
    <NavigationButton
      route={appRoutePaths.FileUpload}
      classes="button no-wrap-text upload--button"
      tooltipText="Upload a File"
    >
      <>Upload a File</>
    </NavigationButton>
  );

  const showDelete = (file: FileModel): boolean =>
    file.status === FileStatus.FAILED &&
    !isStateNonAdmin() &&
    canExecuteAppAction(AppActions.DELETE_FILE);

  const handleDelete = async (
    id: string,
    fileName: string | undefined
  ): Promise<void> => {
    confirmContext.setOptions({
      title: `Confirm File Delete?`,
      content: (
        <div className="confirm--message">
          Are you sure you want to delete this file?
          <div className="item--display--confirm">
            <ul>
              <li>{fileName}</li>
            </ul>
          </div>
        </div>
      ),
      confirmText: 'Yes, delete File',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await deleteFile(id, districtId);
        if (isValidCode(response.status)) {
          dispatch(
            fetchFiles({
              leaId: districtId,
              fileStatus: filterStatus,
              uploadedBy: filterUploadedBy,
              startDate: dateRangeValue[0]?.format('YYYY-MM-DD') || defaultStartDate.format('YYYY-MM-DD'),
              endDate: dateRangeValue[1]?.format('YYYY-MM-DD') || defaultEndDate.format('YYYY-MM-DD'),
            })
          );
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  return (
    <FileDetailPage
      heading="Submission Status"
      pageClass="files--list"
      isLoading={isLoading}
      loadingDataId="files-loader"
      loadingText="Getting your Files"
    >
      <div className="filter-container" data-testid="filter-container">
        <div className="filter-dropdown">{filterDropdown}</div>
        <div className="heading-buttons">{UploadButton}</div>
      </div>
      <div>
        <table cellSpacing={0} className="page-view-table">
          <thead>
            <tr>
              <th data-cy="file-name">Name</th>
              <th data-cy="file-date">Date &amp; Time (CT)</th>
              <th data-cy="file-uploaded-by">Uploaded By</th>
              <th data-cy="file-status">Status</th>
              <th data-cy="file-action">Actions</th>
            </tr>
          </thead>
          <tbody>
            {files.length > 0 &&
              files.map((file) => {
                const { filename } = parseFilename(file.fileName);

                return (
                  <tr
                    data-status={file.status}
                    key={`${file.id}-${file.fileName}-${file.uploadDateTimeUtc}`}
                    data-testid={file.id}
                  >
                    <td className="files--list--file-name">
                      <NavigationButton
                        route={appRoutePaths.FileDetail(file.id)}
                        visibleOverride={canUserAccessRoute(
                          appRoutePaths.FileDetail()
                        )}
                        tooltipText="Click to go to File Detail View"
                      >
                        <>{file.fileName?.split(';').pop()}</>
                      </NavigationButton>
                    </td>
                    <td className="files--list--date">
                      {formatToDateTimeString(file.uploadDateTimeUtc)}
                    </td>
                    <td className="files--list--uploaded-by">
                      {prependLastword(file.uploadedBy, ' ', ', ')}
                    </td>
                    <td className="files--list--status">
                      <Pill
                        status={file.status}
                        value={file.status}
                        pillType={PillType.FILE_STATUS}
                      />
                    </td>
                    <td className="files--list--action-container">
                      <div className="action--column">
                        {file.canDownload && (
                          <button
                            data-testid="download-file"
                            className="download-button"
                            aria-label="Download"
                            onClick={() =>
                              handleFileUpload(
                                file.id,
                                file.fileName,
                                selectedDistrict.districtId
                              )
                            }
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Download File'}
                          >
                            <img
                              className="download-button-img"
                              src={'/images/icon-file-large.svg'}
                            />
                          </button>
                        )}
                        {showDelete(file) && (
                          <button
                            data-testid="delete-file"
                            className="delete-button"
                            aria-label="Delete"
                            onClick={() => handleDelete(file.id, filename)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Delete File'}
                          >
                            <img
                              className="delete-button-img"
                              src={'/images/icon-trash copy.svg'}
                            />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {files.length === 0 && (
          <EmptyGridContent iconType={IconType.FILE}>
            <p>No results match your search criteria</p>
            <NavigationButton route={appRoutePaths.FileUpload}>
              <>Try uploading a file</>
            </NavigationButton>
          </EmptyGridContent>
        )}
      </div>
    </FileDetailPage>
  );
};

export default Files;
